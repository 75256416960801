import styled from "styled-components";

export const BrandsImage = styled.img`
  align-self: flex-end;
  width: 168px;
  height: 32px;
  object-fit: contain;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 4px;
`;

export const MercadoPagoImage = styled.img`
  width: 320px;
  height: 64px;
  object-fit: contain;
`;
