import Col from "../Col";
import RadioButton from "../RadioButton";
import {
  CardHolder,
  CardNumber,
  CardSelectContainer,
  CardSelectedContainer,
} from "./styled";
import cardBrandFormat from "../../helpers/cardBrandFormat";

const CardSelect = ({ data, selected, onSelect }) => {
  if (selected) {
    return (
      <CardSelectedContainer>
        <RadioButton checked />
        <Col>
          <CardNumber>
            {cardBrandFormat(data.brand)} que termina en {data.lastFourDigits}
          </CardNumber>
          <CardHolder>{data.cardholderName}</CardHolder>
        </Col>
      </CardSelectedContainer>
    );
  }
  return (
    <CardSelectContainer onClick={onSelect}>
      <RadioButton />
      <Col>
        <CardNumber>
          {cardBrandFormat(data.brand)} que termina en {data.lastFourDigits}
        </CardNumber>
        <CardHolder>{data.cardholderName}</CardHolder>
      </Col>
    </CardSelectContainer>
  );
};

export default CardSelect;
