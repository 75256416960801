import { Fragment } from "react";

import Row from "../../components/Row";
import Col from "../../components/Col";
import Portal from "../../components/Portal";
import LoadingModal from "../../components/LoadingModal";
import Modal from "../../components/Modal";
import CardSelect from "../../components/CardSelect";
import CardForm from "../../components/CardForm";
import { ButtonText } from "../../components/Button";
import { CardSelectContainer, Hr } from "./styled";
import { getModalType, setModal } from "../../helpers/handleForm";
import handleBackToBind from "../../helpers/handleBackToBind";

const CardProcess = ({
  formMethods,
  isAddingCard,
  setIsAddingCard,
  cards = [],
  cardSelected,
  setCardSelected,
}) => {
  const handleClose = () => {
    if (getModalType("result-tokenization") === "success") {
      setModal({ isOpen: false, id: "result-tokenization" });
    } else {
      handleBackToBind();
    }
  };

  return (
    <>
      <Portal id="loading-tokenization">
        <LoadingModal isOpen />
      </Portal>
      <Portal id="result-tokenization">
        <Modal isOpen onAccept={handleClose} onCancel={handleClose} />
      </Portal>
      {isAddingCard || cards.length === 0 ? (
        <CardForm formMethods={formMethods} />
      ) : (
        <>
          <Row>
            <CardSelectContainer>
              {cards.map((card, index) => {
                return (
                  <Fragment key={index}>
                    <CardSelect
                      data={card}
                      selected={cardSelected === index}
                      onSelect={() => setCardSelected(index)}
                    />
                    {index !== cards.length - 1 && <Hr />}
                  </Fragment>
                );
              })}
            </CardSelectContainer>
          </Row>
          <Row>
            <Col>
              <ButtonText onClick={() => setIsAddingCard(true)}>
                + Agregar una tarjeta de crédito o débito
              </ButtonText>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default CardProcess;
