import { Fragment, useState } from "react";

import {
  ActiveTabItem,
  TabContainer,
  TabContent,
  TabItem,
  TabMenu,
} from "./styled";

const Tabs = ({ tabData = [], onSelect }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
    onSelect(index);
  };

  return (
    <TabContainer>
      <TabMenu>
        {tabData.map((tab, index) => (
          <Fragment key={index}>
            {activeTab === index ? (
              <ActiveTabItem onClick={() => handleTabClick(index)}>
                {tab.label}
              </ActiveTabItem>
            ) : (
              <TabItem onClick={() => handleTabClick(index)}>
                {tab.label}
              </TabItem>
            )}
          </Fragment>
        ))}
      </TabMenu>
      <TabContent>{tabData[activeTab].content()}</TabContent>
    </TabContainer>
  );
};

export default Tabs;
