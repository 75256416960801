import API from "./index";

export const createPayment = ({ cardId, token }) => {
  return API.post(
    "/create-payment",
    {
      cardId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const createSubscription = ({ cardId, token }) => {
  return API.post(
    "/create-subscription",
    {
      cardId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const alterSubscription = ({ token }) => {
  return API.post(
    "/alter-subscription",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const alterCheckoutPro = ({ token }) => {
  return API.post(
    "/alter-checkout-pro",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
