import camelToUnderscore from "./camelToUnderscore";

export const setDisplayByQuerySelector = (querySelector, display) => {
  const element = document.querySelector(querySelector);
  if (element) {
    element.style.display = display;
  }
};

export const setTextByQuerySelector = (querySelector, text) => {
  const element = document.querySelector(querySelector);
  if (element) {
    element.innerText = text;
  }
};

export const setBorderByQuerySelector = (querySelector, border) => {
  const element = document.querySelector(querySelector);
  if (element) {
    element.style.border = border;
  }
};

export const setHTMLByQuerySelector = (querySelector, html) => {
  const element = document.querySelector(querySelector);
  if (element) {
    element.innerHTML = html;
  }
};

export const getModalType = (id) => {
  const success = document.querySelector(`#${id} .modal-success-icon`);
  const warning = document.querySelector(`#${id} .modal-warning-icon`);
  if (success) {
    return "success";
  } else if (warning) {
    return "warning";
  }
  return null;
};

export const setModal = ({
  isOpen = false,
  id = "",
  type = "success",
  title = "",
  message = "",
}) => {
  if (isOpen) {
    setDisplayByQuerySelector(`#${id}`, "block");
    setTextByQuerySelector(`#${id} .modal-title`, title);
    setTextByQuerySelector(`#${id} .modal-message`, message);
    if (type === "success") {
      setDisplayByQuerySelector(`#${id} .modal-success-icon`, "block");
      setDisplayByQuerySelector(`#${id} .modal-warning-icon`, "none");
    } else {
      setDisplayByQuerySelector(`#${id} .modal-success-icon`, "none");
      setDisplayByQuerySelector(`#${id} .modal-warning-icon`, "block");
    }
  } else {
    setDisplayByQuerySelector(`#${id}`, "none");
  }
};

export const setLoadingModal = ({
  isOpen = false,
  id = "",
  title = "",
  message = "",
}) => {
  if (isOpen) {
    setDisplayByQuerySelector(`#${id}`, "block");
    setTextByQuerySelector(`#${id} .modal-title`, title);
    setTextByQuerySelector(`#${id} .modal-message`, message);
  } else {
    setDisplayByQuerySelector(`#${id}`, "none");
  }
};

export const setError = ({ id = "", message = "" }) => {
  setBorderByQuerySelector(
    `#mp_input_${camelToUnderscore(id)}`,
    "1px solid #ff4242"
  );
  setHTMLByQuerySelector(
    `.mp_error_${camelToUnderscore(id)}`,
    `<p>${message}</p>`
  );
};

export const clearErrors = () => {
  setBorderByQuerySelector("#mp_input_cardholder_name", "1px solid #cecece");
  setBorderByQuerySelector("#mp_input_card_number", "1px solid #cecece");
  setBorderByQuerySelector("#mp_input_expiration_date", "1px solid #cecece");
  setBorderByQuerySelector("#mp_input_security_code", "1px solid #cecece");
  setHTMLByQuerySelector(".mp_error_cardholder_name", "");
  setHTMLByQuerySelector(".mp_error_card_number", "");
  setHTMLByQuerySelector(".mp_error_expiration_date", "");
  setHTMLByQuerySelector(".mp_error_security_code", "");
};
