import Description from "../Description";
import Button, { ButtonOutline } from "../Button";
import {
  DialogOverlay,
  DialogContainer,
  HeaderContainer,
  H1,
  Hr,
  DialogBody,
  DialogFooter,
  Icon,
} from "./styled";
import WarningIcon from "../../assets/img/warning.svg";
import CloseIcon from "../../assets/img/close.svg";

const Dialog = ({
  isOpen = false,
  title = "",
  message = "",
  onAccept = () => null,
  onCancel = () => null,
}) => {
  if (!isOpen) return null;

  return (
    <DialogOverlay>
      <DialogContainer>
        <HeaderContainer>
          <Icon className="dialog-warning-icon" src={WarningIcon} />
          <H1 className="dialog-title">{title}</H1>
          <Icon className="close" src={CloseIcon} onClick={onCancel} />
        </HeaderContainer>
        <Hr />
        <DialogBody>
          <Description className="dialog-message">{message}</Description>
        </DialogBody>
        <DialogFooter>
          <ButtonOutline onClick={onCancel}>Atrás</ButtonOutline>
          <Button onClick={onAccept}>Eliminar</Button>
        </DialogFooter>
      </DialogContainer>
    </DialogOverlay>
  );
};

export default Dialog;
