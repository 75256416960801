import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Container from "../../components/Container";
import LoadingModal from "../../components/LoadingModal";
import Modal from "../../components/Modal";
import Header from "../../components/Header";
import Body from "../../components/Body";
import Description from "../../components/Description";
import Footer from "../../components/Footer";
import Button, { ButtonOutline } from "../../components/Button";
import { AlterDetail, AlterDetailsContainer } from "./styled";
import useCustomer from "../../hooks/useCustomer";
import handleBackToBind from "../../helpers/handleBackToBind";
import { alterSubscription } from "../../api/payments";
import { errors, loadingMessages, success } from "../../constants/responses";

const AlterSubscription = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { customer, isLoadingCustomer, errorCustomer } = useCustomer();
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState({
    isOpen: false,
    title: "",
    message: "",
  });

  const getIVA = () => {
    const total = parseFloat(customer?.subscription?.transactionAmount);
    const iva = total - total / 1.16;
    return iva;
  };

  const getSubtotal = () => {
    const total = parseFloat(customer?.subscription?.transactionAmount);
    const subtotal = total - getIVA();
    return subtotal;
  };

  const handleUpdate = () => {
    setLoading(true);
    alterSubscription({ token })
      .then((response) => {
        if (response?.data?.success) {
          setModalState({
            isOpen: true,
            type: "success",
            title: success.ALTER_SUBSCRIPTION.title,
            message: success.ALTER_SUBSCRIPTION.message,
          });
        } else {
          setModalState({
            isOpen: true,
            type: "error",
            title: errors.ALTER_SUBSCRIPTION.title,
            message: response?.data?.message
              ? `${errors.ALTER_SUBSCRIPTION.message}\n\nMensaje de api mercadopago: ${response.data.message}`
              : errors.ALTER_SUBSCRIPTION.message,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.data?.error === errors.TOKEN_HAS_EXPIRED.value) {
          setModalState({
            isOpen: true,
            type: "error",
            title: errors.TOKEN_HAS_EXPIRED.title,
            message: errors.TOKEN_HAS_EXPIRED.message,
          });
        } else {
          setModalState({
            isOpen: true,
            type: "error",
            title: errors.ALTER_SUBSCRIPTION.title,
            message: error?.response?.data?.message
              ? `${errors.ALTER_SUBSCRIPTION.message}\n\nMensaje de api mercadopago: ${error.response.data.message}`
              : errors.ALTER_SUBSCRIPTION.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (errorCustomer.message) {
      setModalState({
        isOpen: true,
        type: "error",
        title: errorCustomer.title,
        message: errorCustomer.message,
        onAccept: handleBackToBind,
        onCancel: handleBackToBind,
      });
    } else {
      setModalState({
        isOpen: false,
        title: "",
        message: "",
      });
    }
  }, [errorCustomer]);

  return (
    <Container>
      <LoadingModal
        isOpen={isLoadingCustomer}
        title={loadingMessages.COMMON.title}
        message={loadingMessages.COMMON.message}
      />
      <LoadingModal
        isOpen={loading}
        title={loadingMessages.COMMON.title}
        message={loadingMessages.COMMON.message}
      />
      <Modal
        isOpen={modalState.isOpen}
        type={modalState.type}
        title={modalState.title}
        message={modalState.message}
        onAccept={handleBackToBind}
        onCancel={handleBackToBind}
      />
      <Header>Actualizar Suscripción</Header>
      <Body>
        <Description>
          Hola{" "}
          <b style={{ fontWeight: "500" }}>{customer?.customer?.first_name}</b>
          <br />
          <br />
          Se actualizará el pago de tu plan{" "}
          <b style={{ fontWeight: "500" }}>
            {customer?.subscription?.description}
          </b>{" "}
          con los beneficios previamente seleccionados y se ajustará al
          siguiente monto:
          <AlterDetailsContainer>
            <AlterDetail>
              <p>Subtotal:</p>
              <p>
                {parseFloat(getSubtotal()).toLocaleString("es-MX", {
                  style: "currency",
                  currency: "MXN",
                })}
              </p>
            </AlterDetail>
            <AlterDetail>
              <p>IVA:</p>
              <p>
                {parseFloat(getIVA()).toLocaleString("es-MX", {
                  style: "currency",
                  currency: "MXN",
                })}
              </p>
            </AlterDetail>
            <AlterDetail>
              <b style={{ fontWeight: "500" }}>Total:</b>
              <b style={{ fontWeight: "500" }}>
                {parseFloat(
                  customer?.subscription?.transactionAmount
                ).toLocaleString("es-MX", {
                  style: "currency",
                  currency: "MXN",
                })}
              </b>
            </AlterDetail>
          </AlterDetailsContainer>
        </Description>
      </Body>
      <Footer>
        <ButtonOutline onClick={handleBackToBind} style={{ width: 260 }}>
          Volver a Seleccionar Beneficios
        </ButtonOutline>
        <Button onClick={handleUpdate}>Confirmar</Button>
      </Footer>
    </Container>
  );
};

export default AlterSubscription;
