import { useSearchParams } from "react-router-dom";

import AlterSubscription from "./AlterSubscription";
import CreatePayment from "./CreatePayment";
import Container from "../../components/Container";
import Modal from "../../components/Modal";
import handleBackToBind from "../../helpers/handleBackToBind";
import { paymentType } from "../../constants/paymentType";
import { errors } from "../../constants/responses";

const PaymentProcess = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  if (type === paymentType.RECURRING_ALTER) {
    return <AlterSubscription />;
  }

  if (
    type === paymentType.SINGLE ||
    type === paymentType.RECURRING ||
    type === paymentType.RECURRING_PRORRATED
  ) {
    return <CreatePayment />;
  }

  return (
    <Container>
      <Modal
        isOpen
        type="error"
        title={errors.TYPE_UNDEFINED.title}
        message={errors.TYPE_UNDEFINED.message}
        onAccept={handleBackToBind}
        onCancel={handleBackToBind}
      />
    </Container>
  );
};

export default PaymentProcess;
