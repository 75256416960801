import React from "react";
import ReactDOM from "react-dom/client";
import { initMercadoPago } from "@mercadopago/sdk-react";

import App from "./App";
import "./index.css";

console.log(process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY);

initMercadoPago(process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY, {
  locale: "es-MX",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
