import styled from "styled-components";

import Description from "../Description";

export const CardSelectContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 20px;
`;

export const CardSelectedContainer = styled(CardSelectContainer)`
  background-color: #eee;
`;

export const CardNumber = styled(Description)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-weight: 500;
  font-size: 14px;
`;

export const CardHolder = styled(Description)`
  font-size: 14px;
`;
