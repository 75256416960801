export const cardBrand = Object.freeze({
  VISA: {
    label: "Visa",
    value: "visa",
  },
  MASTERCARD: {
    label: "MasterCard",
    value: "master",
  },
  AMERICAN_EXPRESS: {
    label: "AmericanExpress",
    value: "amex",
  },
});
