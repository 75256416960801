import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Container from "../../components/Container";
import Portal from "../../components/Portal";
import LoadingModal from "../../components/LoadingModal";
import Modal from "../../components/Modal";
import Header from "../../components/Header";
import Body from "../../components/Body";
import Description from "../../components/Description";
import CardForm from "../../components/CardForm";
import Footer from "../../components/Footer";
import Button, { ButtonOutline } from "../../components/Button";
import useAddCard from "../../hooks/useAddCard";
import useCustomer from "../../hooks/useCustomer";
import handleBackToBind from "../../helpers/handleBackToBind";
import { loadingMessages } from "../../constants/responses";

const AddCard = () => {
  const formMethods = useForm();
  const { tokenization } = useAddCard({
    onTokenizationSuccess: handleBackToBind,
  });
  const { customer, isLoadingCustomer, errorCustomer } = useCustomer();
  const [modalState, setModalState] = useState({
    isOpen: false,
    title: "",
    message: "",
  });

  useEffect(() => {
    if (errorCustomer.message) {
      setModalState({
        isOpen: true,
        type: "error",
        title: errorCustomer.title,
        message: errorCustomer.message,
      });
    } else {
      setModalState({
        isOpen: false,
        title: "",
        message: "",
      });
    }
  }, [errorCustomer]);

  return (
    <Container>
      <Portal id="loading-tokenization">
        <LoadingModal isOpen />
      </Portal>
      <Portal id="result-tokenization">
        <Modal isOpen onAccept={handleBackToBind} onCancel={handleBackToBind} />
      </Portal>
      <LoadingModal
        isOpen={isLoadingCustomer}
        title={loadingMessages.COMMON.title}
        message={loadingMessages.COMMON.message}
      />
      <Modal
        isOpen={modalState.isOpen}
        type={modalState.type}
        title={modalState.title}
        message={modalState.message}
        onAccept={handleBackToBind}
        onCancel={handleBackToBind}
      />
      <Header>Agregar tarjeta</Header>
      <Body>
        <Description>
          Hola{" "}
          <b style={{ fontWeight: "500" }}>{customer?.customer?.first_name}</b>,
          agrega una tarjeta de débito o crédito.
        </Description>
        <CardForm formMethods={formMethods} />
      </Body>
      <Footer>
        <ButtonOutline onClick={handleBackToBind}>Cancelar</ButtonOutline>
        <Button onClick={formMethods.handleSubmit(tokenization)}>
          Guardar
        </Button>
      </Footer>
    </Container>
  );
};

export default AddCard;
