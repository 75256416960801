import API from "./index";

export const cardRegister = ({ token, ...cardTokenData }) => {
  return API.post("/cardregister", cardTokenData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCards = ({ token }) => {
  return API.get("/cards", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const setDefaultCard = ({ cardId, token }) => {
  return API.put(
    "/setdefault",
    { cardId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const deleteCard = ({ cardId, token }) => {
  return API.delete(`/card/${cardId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
