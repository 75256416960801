import styled from "styled-components";

export const CardContainer = styled.div`
  position: relative;
  width: 138px;
  height: 83px;
  border-radius: 6px;
  background-color: #7fbddb;
`;

export const CardBrand = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 32px;
  height: 20px;
  object-fit: contain;
`;

export const CardLabel = styled.div`
  position: absolute;
  right: 0;
  bottom: 13px;
  width: 84px;
  height: 18px;
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  font-size: 10px;
  color: #ffffff;
  background-color: #2f4d63;
  display: flex;
  justify-content: center;
  align-items: center;
`;
