import styled from "styled-components";

export const InputContainer = styled.div`
  border: 1px solid #cecece;
  border-radius: 5px;
  overflow: hidden;
  padding: 0;
`;

export const InputText = styled.input`
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.neutral.main};
  background-color: #fff;
  width: 100%;
  height: 34px;
  padding: 0 10px;
  outline: none;
`;
