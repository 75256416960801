import styled from "styled-components";

const Description = styled.p`
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.neutral.main};
  margin: 0;
`;

export default Description;
