import { PARENT_MODAL_DOMAIN, modalMessages } from "../constants/responses";

const handleCheckoutProRedirect = () => {
  window.parent.postMessage(
    { message: modalMessages.CHECKOUTPRO_REDIRECT },
    PARENT_MODAL_DOMAIN
  );
  window.close();
};

export default handleCheckoutProRedirect;
