import styled from "styled-components";

const Footer = styled.footer`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 20px;
  padding: 30px;
`;

export default Footer;
