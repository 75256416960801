import Description from "../Description";
import {
  ModalOverlay,
  ModalContainer,
  HeaderContainer,
  H1,
  ModalBody,
  Spinner,
} from "./styled";
import SpinnerIcon from "../../assets/img/spinner.svg";

const LoadingModal = ({ isOpen = false, title = "", message = "" }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContainer>
        <HeaderContainer>
          <H1 className="modal-title">{title}</H1>
        </HeaderContainer>
        <ModalBody>
          <Description className="modal-message">{message}</Description>
          <Spinner src={SpinnerIcon} />
        </ModalBody>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default LoadingModal;
