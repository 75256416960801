import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { paymentType } from "../constants/paymentType";
import { errors, success } from "../constants/responses";
import { deleteCard, getCards, setDefaultCard } from "../api/cards";

const useCards = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const type = searchParams.get("type");
  const [cards, setCards] = useState([]);
  const [isLoadingCards, setIsLoadingCards] = useState(true);
  const [errorCards, setErrorCards] = useState({
    title: "",
    message: "",
  });
  const [successCards, setSuccessCards] = useState({
    title: "",
    message: "",
  });

  const handleFetchCards = useCallback(() => {
    getCards({ token })
      .then((response) => {
        let filteredCards = response?.data?.cards || [];

        if (type === paymentType.RECURRING) {
          filteredCards = response?.data?.cards.filter(
            (card) => card.status === "new"
          );
        }

        if (filteredCards.length) {
          setCards(
            filteredCards.sort((a, b) => {
              if (a.isDefault) {
                return -1;
              }
              if (b.isDefault) {
                return 1;
              }
              return 0;
            })
          );
        } else {
          setCards([]);
        }
      })
      .catch((error) => {
        setCards([]);
        if (error?.response?.data?.error === errors.TOKEN_HAS_EXPIRED.value) {
          setErrorCards({
            title: errors.TOKEN_HAS_EXPIRED.title,
            message: errors.TOKEN_HAS_EXPIRED.message,
          });
        } else {
          setErrorCards({
            title: errors.GET_CARDS.title,
            message: error?.response?.data?.message
              ? `${errors.GET_CARDS.message}\n\nMensaje de api mercadopago: ${error.response.data.message}`
              : errors.GET_CARDS.message,
          });
        }
      })
      .finally(() => {
        setIsLoadingCards(false);
      });
  }, [token, type]);

  const handleSetDefaultCard = (cardId) => {
    setIsLoadingCards(true);
    setDefaultCard({ cardId, token })
      .then((response) => {
        if (response?.data?.success) {
          setSuccessCards({
            title: success.SET_DEFAULT_CARD.title,
            message: success.SET_DEFAULT_CARD.message,
          });
          handleFetchCards();
        } else {
          setErrorCards({
            title: errors.SET_DEFAULT_CARD.title,
            message: errors.SET_DEFAULT_CARD.message,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.data?.error === errors.TOKEN_HAS_EXPIRED.value) {
          setErrorCards({
            title: errors.TOKEN_HAS_EXPIRED.title,
            message: errors.TOKEN_HAS_EXPIRED.message,
          });
        } else {
          setErrorCards({
            title: errors.SET_DEFAULT_CARD.title,
            message: error?.response?.data?.message
              ? `${errors.SET_DEFAULT_CARD.message}\n\nMensaje de api mercadopago: ${error.response.data.message}`
              : errors.SET_DEFAULT_CARD.message,
          });
        }
      })
      .finally(() => {
        setIsLoadingCards(false);
      });
  };

  const handleDeleteCard = (cardId) => {
    setIsLoadingCards(true);
    deleteCard({ cardId, token })
      .then((response) => {
        if (response?.data?.success) {
          setSuccessCards({
            title: success.DELETE_CARD.title,
            message: success.DELETE_CARD.message,
          });
          handleFetchCards();
        } else {
          setErrorCards({
            title: errors.DELETE_CARD.title,
            message: errors.DELETE_CARD.message,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.data?.error === errors.TOKEN_HAS_EXPIRED.value) {
          setErrorCards({
            title: errors.TOKEN_HAS_EXPIRED.title,
            message: errors.TOKEN_HAS_EXPIRED.message,
          });
        } else {
          setErrorCards({
            title: errors.DELETE_CARD.title,
            message: error?.response?.data?.message
              ? `${errors.DELETE_CARD.message}\n\nMensaje de api mercadopago: ${error.response.data.message}`
              : errors.DELETE_CARD.message,
          });
        }
      })
      .finally(() => {
        setIsLoadingCards(false);
      });
  };

  useEffect(() => {
    handleFetchCards();
  }, [handleFetchCards]);

  return {
    cards,
    handleFetchCards,
    handleSetDefaultCard,
    handleDeleteCard,
    isLoadingCards,
    successCards,
    errorCards,
  };
};

export default useCards;
