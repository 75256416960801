import { CardBrand, CardContainer, CardLabel } from "./styled";

const Card = ({ brand, thumb, isDefault = false }) => {
  return (
    <CardContainer>
      <CardBrand alt={brand} src={thumb} />
      {isDefault && <CardLabel>predeterminado</CardLabel>}
    </CardContainer>
  );
};

export default Card;
