import styled from "styled-components";

const Label = styled.label`
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.neutral.main};
  margin-bottom: 6px;
`;

export default Label;
