import API from "./index";

export const createPreference = (
  { description, price, quantity, token },
  options
) => {
  return API.post(
    "/createpreference",
    { description, price, quantity },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      ...options,
    }
  );
};
