import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { errors } from "../constants/responses";
import { getCustomer } from "../api/customer";

const useCustomer = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [customer, setCustomer] = useState({});
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(true);
  const [errorCustomer, setErrorCustomer] = useState({
    title: "",
    message: "",
  });

  useEffect(() => {
    getCustomer({ token })
      .then((response) => {
        setCustomer(response.data);
      })
      .catch((error) => {
        setCustomer({});
        if (error?.response?.data?.error === errors.TOKEN_HAS_EXPIRED.value) {
          setErrorCustomer({
            title: errors.TOKEN_HAS_EXPIRED.title,
            message: errors.TOKEN_HAS_EXPIRED.message,
          });
        } else {
          setErrorCustomer({
            title: errors.GET_CUSTOMER.title,
            message: error?.response?.data?.message
              ? `${errors.GET_CUSTOMER.message}\n\nMensaje de api mercadopago: ${error.response.data.message}`
              : errors.GET_CUSTOMER.message,
          });
        }
      })
      .finally(() => {
        setIsLoadingCustomer(false);
      });
  }, [token]);

  return { customer, isLoadingCustomer, errorCustomer };
};

export default useCustomer;
