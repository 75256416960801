import { createCardToken } from "@mercadopago/sdk-react";
import { useSearchParams } from "react-router-dom";

import {
  clearErrors,
  setError,
  setLoadingModal,
  setModal,
} from "../helpers/handleForm";
import monthFormat from "../helpers/monthFormat";
import { errors, loadingMessages, success } from "../constants/responses";
import { cardRegister } from "../api/cards";
import { cardFormValidationMessages } from "../constants/validations";

const useAddCard = ({ onTokenizationSuccess }) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const tokenization = ({ cardholderName }) => {
    setLoadingModal({
      isOpen: true,
      id: "loading-tokenization",
      title: loadingMessages.COMMON.title,
      message: loadingMessages.COMMON.message,
    });
    clearErrors();
    const timeout = setTimeout(() => {
      createCardToken({ cardholderName })
        .then((cardTokenData) => {
          console.log(cardTokenData);
          if (cardholderName && cardholderName.replace(/\s/g, "").length > 0) {
            cardRegister({
              cardToken: cardTokenData.id,
              cardholderName: cardTokenData.cardholder.name,
              firstSixDigits: cardTokenData.first_six_digits,
              lastFourDigits: cardTokenData.last_four_digits,
              cardNumberLength: cardTokenData.card_number_length,
              expirationMonth: monthFormat(cardTokenData.expiration_month),
              expirationYear: cardTokenData.expiration_year.toString(),
              securityCodeLength: cardTokenData.security_code_length,
              token,
            })
              .then((response) => {
                if (response?.data?.success) {
                  setModal({
                    isOpen: true,
                    id: "result-tokenization",
                    type: "success",
                    title: success.POST_CARD.title,
                    message: success.POST_CARD.message,
                  });
                  onTokenizationSuccess();
                } else {
                  setModal({
                    isOpen: true,
                    id: "result-tokenization",
                    type: "error",
                    title: errors.POST_CARD.title,
                    message: response?.data?.message
                      ? `${errors.POST_CARD.message}\n\nMensaje de api mercadopago: ${response.data.message}`
                      : errors.POST_CARD.message,
                  });
                }
              })
              .catch((error) => {
                console.log(error);
                if (
                  error?.response?.data?.error ===
                  errors.TOKEN_HAS_EXPIRED.value
                ) {
                  setModal({
                    isOpen: true,
                    id: "result-tokenization",
                    type: "error",
                    title: errors.TOKEN_HAS_EXPIRED.title,
                    message: errors.TOKEN_HAS_EXPIRED.message,
                  });
                } else {
                  setModal({
                    isOpen: true,
                    id: "result-tokenization",
                    type: "error",
                    title: errors.POST_CARD.title,
                    message: error?.response?.data?.message
                      ? `${errors.POST_CARD.message}\n\nMensaje de api mercadopago: ${error.response.data.message}`
                      : errors.POST_CARD.message,
                  });
                }
              })
              .finally(() => {
                setLoadingModal({ isOpen: false, id: "loading-tokenization" });
              });
          } else {
            setError({
              id: "cardholderName",
              message: "El nombre del titular está vacío.",
            });
            setLoadingModal({ isOpen: false, id: "loading-tokenization" });
          }
        })
        .catch((error) => {
          if (
            !cardholderName ||
            cardholderName.replace(/\s/g, "").length === 0
          ) {
            setError({
              id: "cardholderName",
              message: "El nombre del titular está vacío.",
            });
          }
          if (error.length) {
            error.forEach(({ field, message }) => {
              const errorMessage =
                cardFormValidationMessages[message] || message;
              setError({ id: field, message: errorMessage });
            });
          }
          setLoadingModal({ isOpen: false, id: "loading-tokenization" });
        });
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  };

  return { tokenization };
};

export default useAddCard;
