import styled from "styled-components";

import Footer from "../Footer";

export const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
`;

export const DialogContainer = styled.div`
  position: fixed;
  top: 100px;
  left: 20%;
  right: 20%;
  z-index: 101;
  padding: 20px;
  background: #fff;
  @media (max-width: 445px) {
    left: 0;
    right: 0;
  }
`;

export const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const Icon = styled.img`
  width: 26px;
  height: 26px;
  object-fit: contain;
  &.close {
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin-left: auto;
  }
`;

export const H1 = styled.h1`
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral.main};
  padding: 0;
`;

export const Hr = styled.hr`
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
  padding: 0;
  margin: 10px 0;
`;

export const DialogBody = styled.div`
  padding: 0 10px;
  margin: 0 auto;
`;

export const DialogFooter = styled(Footer)`
  padding: 0;
  margin-top: 14px;
`;
