import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Row from "../../components/Row";
import Col from "../../components/Col";
import Container from "../../components/Container";
import Portal from "../../components/Portal";
import LoadingModal from "../../components/LoadingModal";
import Dialog from "../../components/Dialog";
import Modal from "../../components/Modal";
import Header from "../../components/Header";
import Body from "../../components/Body";
import Description from "../../components/Description";
import CardForm from "../../components/CardForm";
import CardOptions from "../../components/CardOptions";
import Footer from "../../components/Footer";
import Button, { ButtonOutline, ButtonText } from "../../components/Button";
import { CardOptionsContainer, Hr } from "./styled";
import useCards from "../../hooks/useCards";
import useCustomer from "../../hooks/useCustomer";
import useAddCard from "../../hooks/useAddCard";
import { setModal } from "../../helpers/handleForm";
import handleBackToBind from "../../helpers/handleBackToBind";
import { loadingMessages } from "../../constants/responses";

const PaymentMethods = () => {
  const formMethods = useForm();
  const {
    cards,
    handleFetchCards,
    handleSetDefaultCard,
    handleDeleteCard,
    isLoadingCards,
    successCards,
    errorCards,
  } = useCards();
  const { tokenization } = useAddCard({
    onTokenizationSuccess: () => {
      setIsAddingCard(false);
      formMethods.reset();
      handleFetchCards();
    },
  });
  const { customer, isLoadingCustomer, errorCustomer } = useCustomer();
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [modalState, setModalState] = useState({
    isOpen: false,
    title: "",
    message: "",
  });
  const [dialogState, setDialogState] = useState({ isOpen: false });

  const handleAddCard = () => {
    setIsAddingCard(true);
  };

  const handleClose = () => {
    setModal({ isOpen: false, id: "result-tokenization" });
  };

  const handleBack = () => {
    if (isAddingCard && cards.length) {
      setIsAddingCard(false);
    } else {
      handleBackToBind();
    }
  };

  const renderBackLabel = () => {
    return "Atrás";
  };

  const handleSave = () => {
    if (isAddingCard) {
      formMethods.handleSubmit(tokenization)();
    } else {
      handleBackToBind();
    }
  };

  const renderSaveLabel = () => {
    if (isAddingCard) {
      return "Agregar";
    }
    return "Regresar a bind";
  };

  useEffect(() => {
    if (!isLoadingCustomer || !isLoadingCards) {
      if (cards.length) {
        setIsAddingCard(false);
      } else {
        setIsAddingCard(true);
      }
    }
  }, [cards, isLoadingCustomer, isLoadingCards]);

  useEffect(() => {
    if (successCards.message) {
      setModalState({
        isOpen: true,
        type: "success",
        title: successCards.title,
        message: successCards.message,
      });
    } else {
      setModalState({
        isOpen: false,
        title: "",
        message: "",
      });
    }
  }, [successCards]);

  useEffect(() => {
    if (errorCustomer.message || errorCards.message) {
      setModalState({
        isOpen: true,
        type: "error",
        title: errorCustomer.title || errorCards.title,
        message: errorCustomer.message || errorCards.message,
        onAccept: handleBackToBind,
        onCancel: handleBackToBind,
      });
    } else {
      setModalState({
        isOpen: false,
        title: "",
        message: "",
      });
    }
  }, [errorCustomer, errorCards]);

  return (
    <Container>
      <Portal id="loading-tokenization">
        <LoadingModal isOpen />
      </Portal>
      <Portal id="result-tokenization">
        <Modal isOpen onAccept={handleClose} onCancel={handleClose} />
      </Portal>
      <LoadingModal
        isOpen={isLoadingCustomer || isLoadingCards}
        title={loadingMessages.COMMON.title}
        message={loadingMessages.COMMON.message}
      />
      <Dialog
        isOpen={dialogState.isOpen}
        title="¿Estás seguro que deseas eliminar esta tarjeta?"
        message="Esta acción no se puede deshacer."
        onAccept={dialogState.onAccept}
        onCancel={() => {
          setDialogState({ isOpen: false });
        }}
      />
      <Modal
        isOpen={modalState.isOpen}
        type={modalState.type}
        title={modalState.title}
        message={modalState.message}
        onAccept={() => {
          if (modalState.onAccept) {
            modalState.onAccept();
          } else {
            setModalState({ isOpen: false });
          }
        }}
        onCancel={() => {
          if (modalState.onCancel) {
            modalState.onCancel();
          } else {
            setModalState({ isOpen: false });
          }
        }}
      />
      <Header>Administrar formas de pago</Header>
      <Body>
        <Description>
          Hola{" "}
          <b style={{ fontWeight: "500" }}>{customer?.customer?.first_name}</b>,
          agrega una tarjeta de débito o crédito.
        </Description>
        {!isLoadingCards && (
          <>
            {isAddingCard ? (
              <CardForm formMethods={formMethods} />
            ) : (
              <>
                <Row>
                  <CardOptionsContainer>
                    {cards.map((card, index) => {
                      return (
                        <Fragment key={index}>
                          <CardOptions
                            data={card}
                            onSetDefault={handleSetDefaultCard}
                            onDelete={(cardId) => {
                              setDialogState({
                                isOpen: true,
                                onAccept: () => {
                                  handleDeleteCard(cardId);
                                  setDialogState({ isOpen: false });
                                },
                              });
                            }}
                          />
                          {index !== cards.length - 1 && <Hr />}
                        </Fragment>
                      );
                    })}
                  </CardOptionsContainer>
                </Row>
                <Row>
                  <Col>
                    <ButtonText onClick={handleAddCard}>
                      + Agregar una tarjeta de crédito o débito
                    </ButtonText>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Body>
      <Footer>
        {isAddingCard ? (
          <>
            <ButtonOutline onClick={handleBack}>
              {renderBackLabel()}
            </ButtonOutline>
            <Button onClick={handleSave}>{renderSaveLabel()}</Button>
          </>
        ) : (
          <Button onClick={handleSave} style={{ width: 140 }}>
            {renderSaveLabel()}
          </Button>
        )}
      </Footer>
    </Container>
  );
};

export default PaymentMethods;
