import { useEffect, useState } from "react";
import { Wallet } from "@mercadopago/sdk-react";
import { useSearchParams } from "react-router-dom";

import Row from "../../components/Row";
import Col from "../../components/Col";
import Modal from "../../components/Modal";
import LoadingModal from "../../components/LoadingModal";
import handleBackToBind from "../../helpers/handleBackToBind";
import handleCheckoutProRedirect from "../../helpers/handleCheckoutProRedirect";
import { createPreference } from "../../api/preference";
import { alterCheckoutPro } from "../../api/payments";
import { paymentType } from "../../constants/paymentType";
import { errors, loadingMessages } from "../../constants/responses";

const CheckoutPro = ({ customer }) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const type = searchParams.get("type");
  const [preferenceId, setPreferenceId] = useState("");
  const [isLoadingPreference, setIsLoadingPreference] = useState(false);
  const [modalState, setModalState] = useState({
    isOpen: false,
    title: "",
    message: "",
  });

  useEffect(() => {
    setIsLoadingPreference(true);
    const controller = new AbortController();
    createPreference(
      {
        description: customer?.subscription?.description,
        price:
          type === paymentType.RECURRING_PRORRATED
            ? customer?.subscription?.singlePaymentAmount
            : customer?.subscription?.transactionAmount,
        quantity: 1,
        token,
      },
      { signal: controller.signal, delay: 1000 }
    )
      .then((res) => {
        console.log(res);
        if (res?.data?.id) {
          setPreferenceId(res.data.id);
        } else {
          setModalState({
            isOpen: true,
            type: "error",
            title: errors.CHECKOUTPRO_ERROR.title,
            message: errors.CHECKOUTPRO_ERROR.message,
          });
        }
      })
      .catch((error) => {
        if (!controller.signal.aborted) {
          console.error(error);
          if (error?.response?.data?.error === errors.TOKEN_HAS_EXPIRED.value) {
            setModalState({
              isOpen: true,
              type: "error",
              title: errors.TOKEN_HAS_EXPIRED.title,
              message: errors.TOKEN_HAS_EXPIRED.message,
            });
          } else {
            setModalState({
              isOpen: true,
              type: "error",
              title: errors.CHECKOUTPRO_ERROR.title,
              message: errors.CHECKOUTPRO_ERROR.message,
            });
          }
        }
      })
      .finally(() => {
        setIsLoadingPreference(false);
      });
    return () => {
      controller.abort();
    };
  }, [customer, token, type]);

  return (
    <Row>
      <LoadingModal
        isOpen={isLoadingPreference}
        title={loadingMessages.COMMON.title}
        message={loadingMessages.COMMON.message}
      />
      <Modal
        isOpen={modalState.isOpen}
        type={modalState.type}
        title={modalState.title}
        message={modalState.message}
        onAccept={handleBackToBind}
        onCancel={handleBackToBind}
      />
      <Col>
        {preferenceId && (
          <Wallet
            initialization={{ preferenceId, redirectMode: "blank" }}
            onSubmit={() => {
              if (type === paymentType.RECURRING_PRORRATED) {
                alterCheckoutPro({ token })
                  .then(console.log)
                  .catch(console.error)
                  .finally(() => {
                    handleCheckoutProRedirect();
                  });
              } else {
                setTimeout(() => {
                  handleCheckoutProRedirect();
                }, 1000);
              }
            }}
          />
        )}
      </Col>
    </Row>
  );
};

export default CheckoutPro;
