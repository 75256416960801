import { forwardRef } from "react";

import { InputContainer, InputText } from "./styled";

const Input = forwardRef(({ containerId, containerClass, ...rest }, ref) => {
  return (
    <InputContainer id={containerId} className={containerClass}>
      <InputText ref={ref} {...rest} />
    </InputContainer>
  );
});

export default Input;
