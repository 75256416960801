import { PARENT_MODAL_DOMAIN, modalMessages } from "../constants/responses";

const handleError = () => {
  window.parent.postMessage(
    { message: modalMessages.ERROR },
    PARENT_MODAL_DOMAIN
  );
};

export default handleError;
