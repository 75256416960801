export const cardFormValidationMessages = Object.freeze({
  "cardNumber should be a number.": "El número de tarjeta debe ser un número.",
  "cardNumber is empty.": "El número de tarjeta está vacío.",
  "cardNumber should be of length between '8' and '19'.":
    "El número de tarjeta debe tener entre 8 y 19 dígitos.",
  "expirationMonth should be a number.":
    "El mes de vencimiento debe ser un número.",
  "expirationMonth is empty.": "El mes de vencimiento está vacío.",
  "expirationYear should be of length '2'.":
    "El año de vencimiento debe tener 2 dígitos.",
  "expirationYear should be a number.":
    "El año de vencimiento debe ser un número.",
  "expirationYear is empty.": "El año de vencimiento está vacío.",
  "securityCode should be a number.":
    "El código de seguridad debe ser un número.",
  "securityCode should be of length '3' or '4'.":
    "El código de seguridad debe tener 3 o 4 dígitos.",
  "securityCode is empty.": "El código de seguridad está vacío.",
  "expirationYear value should be greater or equal than 2024.":
    "El año de vencimiento debe ser mayor o igual a 2024.",
  "expirationMonth value should be greater than '02' or expirationYear value should be greater than '2024'.":
    "La fecha de vencimiento debe ser mayor a la fecha actual.",
  "expirationMonth should be a value from 1 to 12.":
    "El mes de vencimiento debe ser un valor entre 1 y 12.",
});
