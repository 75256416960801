import styled from "styled-components";

import Row from "../Row";
import Description from "../Description";
import { ButtonText } from "../Button";

export const RowWrap = styled(Row)`
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const ColCard = styled.div`
  flex: 0;
  @media (max-width: 600px) {
    align-self: center;
    margin-bottom: 10px;
  }
`;

export const CardNumber = styled(Description)`
  font-weight: 500;
  font-size: 14px;
`;

export const CardHolder = styled(Description)`
  font-size: 14px;
`;

export const Option = styled(ButtonText)`
  align-self: flex-start;
  text-align: left;
  font-size: 14px;
  margin: 0;
  width: auto;
`;
