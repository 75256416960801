import styled from "styled-components";

export const Icon = styled.img`
  user-select: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin: auto 0;
`;
