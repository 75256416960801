import styled from "styled-components";

const Body = styled.div`
  padding: 0 30px;
  margin: 0 auto;
  @media (max-width: 445px) {
    padding: 0 10px;
  }
`;

export default Body;
