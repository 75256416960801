import Description from "../Description";
import Button from "../Button";
import {
  ModalOverlay,
  ModalContainer,
  HeaderContainer,
  H1,
  Hr,
  ModalBody,
  ModalFooter,
  Icon,
} from "./styled";
import CheckIcon from "../../assets/img/check.svg";
import WarningIcon from "../../assets/img/warning.svg";
import CloseIcon from "../../assets/img/close.svg";

const Modal = ({
  isOpen = false,
  type = "default",
  title = "",
  message = "",
  onAccept = () => null,
  onCancel = () => null,
}) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContainer>
        <HeaderContainer>
          {type === "success" && (
            <Icon className="modal-success-icon" src={CheckIcon} />
          )}
          {type === "error" && (
            <Icon className="modal-warning-icon" src={WarningIcon} />
          )}
          {type === "default" && (
            <>
              <Icon className="modal-success-icon" src={CheckIcon} />
              <Icon className="modal-warning-icon" src={WarningIcon} />
            </>
          )}
          <H1 className="modal-title">{title}</H1>
          <Icon className="close" src={CloseIcon} onClick={onCancel} />
        </HeaderContainer>
        <Hr />
        <ModalBody>
          <Description className="modal-message">{message}</Description>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onAccept}>Aceptar</Button>
        </ModalFooter>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default Modal;
