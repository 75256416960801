import { Icon } from "./styled";
import RadioCheckedIcon from "../../assets/img/radio-checked.svg";
import RadioIcon from "../../assets/img/radio.svg";

const RadioButton = ({ checked = false }) => {
  if (checked) return <Icon src={RadioCheckedIcon} />;
  return <Icon src={RadioIcon} />;
};

export default RadioButton;
