import { useSearchParams } from "react-router-dom";
import { StatusScreen } from "@mercadopago/sdk-react";

import Container from "../components/Container";
import { ButtonOutline } from "../components/Button";
import handleBackToBind from "../helpers/handleBackToBind";

const PaymentProcessResult = () => {
  const [searchParams] = useSearchParams();
  const collection_id = searchParams.get("collection_id");
  const collection_status = searchParams.get("collection_status");
  const payment_id = searchParams.get("payment_id");
  const status = searchParams.get("status");
  const external_reference = searchParams.get("external_reference");
  const payment_type = searchParams.get("payment_type");
  const merchant_order_id = searchParams.get("merchant_order_id");
  const preference_id = searchParams.get("preference_id");
  const site_id = searchParams.get("site_id");
  const processing_mode = searchParams.get("processing_mode");
  const merchant_account_id = searchParams.get("merchant_account_id");

  console.log({
    collection_id,
    collection_status,
    payment_id,
    status,
    external_reference,
    payment_type,
    merchant_order_id,
    preference_id,
    site_id,
    processing_mode,
    merchant_account_id,
  });

  return (
    <Container>
      <StatusScreen initialization={{ paymentId: payment_id }} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ButtonOutline onClick={handleBackToBind}>Cerrar</ButtonOutline>
      </div>
    </Container>
  );
};

export default PaymentProcessResult;
