import styled from "styled-components";

import { Hr as HeaderHr } from "../../components/Header/styled";

export const CardOptionsContainer = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  margin: 20px auto;
  background-color: #eee;
`;

export const Hr = styled(HeaderHr)`
  margin: 0;
`;
