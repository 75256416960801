import styled from "styled-components";

const ErrorMessage = styled.div`
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  font-size: 12px;
  color: #ff4242;
  margin-top: 8px;
  margin-bottom: 12px;
`;

export default ErrorMessage;
