import {
  CardNumber,
  ExpirationDate,
  SecurityCode,
} from "@mercadopago/sdk-react";
import { FormProvider } from "react-hook-form";

import Row from "../Row";
import Col from "../Col";
import Form from "../Form";
import Label from "../Label";
import Input from "../Input";
import MPInput, { MPInputStyles } from "../MPInput";
import ErrorMessage from "../ErrorMessage";
import { BrandsImage, MercadoPagoImage } from "./styled";
import brandsIcon from "../../assets/img/brands.png";
import mercadopagoIcon from "../../assets/img/mercadopago.png";

const CardForm = ({ formMethods }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <BrandsImage alt="Brands" src={brandsIcon} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Nombre del titular</Label>
            <Input
              {...formMethods.register("cardholderName")}
              containerId="mp_input_cardholder_name"
              containerClass="cardholderName_container"
              placeholder="Como aparece en la tarjeta"
              onFocus={MPInputStyles.onFocus("mp_input_cardholder_name")}
              onBlur={MPInputStyles.onBlur("mp_input_cardholder_name")}
            />
            <ErrorMessage className="mp_error_cardholder_name" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Número de tarjeta</Label>
            <MPInput id="mp_input_card_number">
              <CardNumber
                placeholder="1234 1234 1234 1234"
                style={MPInputStyles.inputStyle}
                customFonts={MPInputStyles.customFonts}
                onFocus={MPInputStyles.onFocus("mp_input_card_number")}
                onBlur={MPInputStyles.onBlur("mp_input_card_number")}
              />
            </MPInput>
            <ErrorMessage className="mp_error_card_number" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Fecha de vencimiento</Label>
            <MPInput id="mp_input_expiration_date">
              <ExpirationDate
                mode="short"
                placeholder="MM/AA"
                style={MPInputStyles.inputStyle}
                customFonts={MPInputStyles.customFonts}
                onFocus={MPInputStyles.onFocus("mp_input_expiration_date")}
                onBlur={MPInputStyles.onBlur("mp_input_expiration_date")}
              />
            </MPInput>
            <ErrorMessage className="mp_error_expiration_date" />
          </Col>
          <Col>
            <Label>Código de seguridad</Label>
            <MPInput id="mp_input_security_code">
              <SecurityCode
                placeholder="123"
                style={MPInputStyles.inputStyle}
                customFonts={MPInputStyles.customFonts}
                onFocus={MPInputStyles.onFocus("mp_input_security_code")}
                onBlur={MPInputStyles.onBlur("mp_input_security_code")}
              />
            </MPInput>
            <ErrorMessage className="mp_error_security_code" />
          </Col>
        </Row>
        <MercadoPagoImage alt="MercadoPago" src={mercadopagoIcon} />
      </Form>
    </FormProvider>
  );
};

export default CardForm;
