import { PARENT_MODAL_DOMAIN, modalMessages } from "../constants/responses";

const handleBackToBind = () => {
  window.parent.postMessage(
    { message: modalMessages.CLOSED },
    PARENT_MODAL_DOMAIN
  );
  window.close();
};

export default handleBackToBind;
