import { cardBrand } from "../constants/cardBrand";

const cardBrandFormat = (brand) => {
  switch (brand) {
    case cardBrand.VISA.value:
      return cardBrand.VISA.label;
    case cardBrand.MASTERCARD.value:
      return cardBrand.MASTERCARD.label;
    case cardBrand.AMERICAN_EXPRESS.value:
      return cardBrand.AMERICAN_EXPRESS.label;
    default:
      return brand;
  }
};

export default cardBrandFormat;
