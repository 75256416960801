import styled from "styled-components";

import { Hr as HeaderHr } from "../../components/Header/styled";

export const CardSelectContainer = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 20px;
`;

export const Hr = styled(HeaderHr)`
  margin: 0;
`;

export const AlterDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #eee;
  padding: 20px;
  margin: 40px 0;
`;

export const AlterDetail = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  & p:first-child,
  b:first-child {
    flex: 1;
    text-align: right;
  }

  & p:last-child,
  b:last-child {
    flex: 1;
    text-align: left;
  }
`;
