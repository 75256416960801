export const cardType = Object.freeze({
  CREDIT_CARD: {
    value: "credit_card",
    label: "crédito",
  },
  DEBIT_CARD: {
    value: "debit_card",
    label: "débito",
  },
});
