import Col from "../Col";
import Card from "../Card";
import { CardHolder, CardNumber, ColCard, Option, RowWrap } from "./styled";
import { cardType } from "../../constants/cardType";

const CardOptions = ({ data, onSetDefault, onDelete }) => {
  const getCardType = (type) => {
    if (cardType.CREDIT_CARD.value === type) {
      return cardType.CREDIT_CARD.label;
    }

    return cardType.DEBIT_CARD.label;
  };

  const handleSetDefaultCard = () => {
    onSetDefault(data.cardId);
  };

  const handleDeleteCard = () => {
    onDelete(data.cardId);
  };

  return (
    <RowWrap>
      <ColCard>
        <Card
          brand={data.brand}
          thumb={data.thumb}
          isDefault={data.isDefault}
        />
      </ColCard>
      <Col>
        <CardNumber>
          Tarjeta de {getCardType(data.type)}
          <br />
          con terminación {data.lastFourDigits}
        </CardNumber>
        <CardHolder>{data.cardholderName}</CardHolder>
        {!data.isDefault && (
          <RowWrap>
            <Option onClick={handleSetDefaultCard}>
              Establecer como predeterminado
            </Option>
            <Option onClick={handleDeleteCard}>Eliminar</Option>
          </RowWrap>
        )}
      </Col>
    </RowWrap>
  );
};

export default CardOptions;
