import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 100px;
  left: 20%;
  right: 20%;
  z-index: 101;
  padding: 20px;
  background: #fff;
  @media (max-width: 445px) {
    left: 0;
    right: 0;
  }
`;

export const HeaderContainer = styled.header``;

export const H1 = styled.h1`
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral.main};
  text-align: center;
  padding: 0;
  margin: 10px;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
`;

export const Spinner = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  animation: spin 1s ease infinite;
  margin: 20px;
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;
