import styled from "styled-components";

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const TabMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 20px 0;
`;

export const TabItem = styled.div`
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 48px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary.main};
  background-color: ${({ theme }) => theme.colors.neutral.light};
  border: 1px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 5px;
`;

export const ActiveTabItem = styled(TabItem)`
  color: ${({ theme }) => theme.colors.neutral.light};
  background-color: ${({ theme }) => theme.colors.primary.main};
  border: 0;
`;

export const TabContent = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.light};
  max-width: 500px;
  margin: 0 auto;
`;
