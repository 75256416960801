import styled from "styled-components";

const Row = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media (max-width: 445px) {
    flex-direction: column;
    gap: 0;
  }
`;

export default Row;
