import styled from "styled-components";

const Container = styled.div`
  padding: 30px 40px 40px 40px;
  margin: 0 auto;
  @media (max-width: 445px) {
    padding: 20px;
  }
`;

export default Container;
