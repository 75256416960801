import styled from "styled-components";

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.primary.main};
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 32px;
  text-align: center;
  text-decoration: none;
  width: 120px;
  height: 32px;
`;

export const ButtonOutline = styled(Button)`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.primary.main};
`;

export const ButtonText = styled(Button)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary.main};
  background-color: transparent;
  padding: 0;
  border: none;
  margin: 0 auto;
  width: auto;
`;

export default Button;
