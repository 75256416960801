import { H1, HeaderContainer, Hr } from "./styled";

const Header = ({ children }) => {
  return (
    <HeaderContainer>
      <H1>{children}</H1>
      <Hr />
    </HeaderContainer>
  );
};

export default Header;
