export const PARENT_MODAL_DOMAIN = process.env.REACT_APP_PARENT_MODAL_DOMAIN;

export const modalMessages = Object.freeze({
  CLOSED: "CLOSED",
  CHECKOUTPRO_REDIRECT: "CHECKOUTPRO_REDIRECT",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
});

export const loadingMessages = Object.freeze({
  COMMON: {
    title: "Cargando...",
    message: "Espere un momento por favor.",
  },
  POST_PAYMENT: {
    title: "Procesando pago...",
    message: "Por favor, espera, no cierres el navegador.",
  },
});

export const success = Object.freeze({
  POST_CARD: {
    title: "Tarjeta agregada con éxito",
    message:
      "Tu tarjeta ha sido agregada exitosamente a tu cuenta. Ahora puedes disfrutar de una experiencia de pago más rápida.",
  },
  POST_PAYMENT: {
    title: "Pago efectuado con éxito",
    message: "Tu pago ha sido procesado con éxito.",
  },
  SET_DEFAULT_CARD: {
    title: "Tarjeta predeterminada actualizada",
    message:
      "Tu tarjeta ha sido configurada como predeterminada en tu cuenta. Ahora, todas las transacciones futuras se realizarán automáticamente con esta tarjeta.",
  },
  DELETE_CARD: {
    title: "Tarjeta eliminada con éxito",
    message: "Tu tarjeta ha sido eliminada.",
  },
  ALTER_SUBSCRIPTION: {
    title: "Suscripción actualizada",
    message: "Tu suscripción ha sido actualizada.",
  },
});

export const errors = Object.freeze({
  GET_CUSTOMER: {
    title: "Error al obtener datos del cliente",
    message:
      "Lamentablemente, no pudimos obtener tus datos de cliente en este momento. Por favor, inténtalo de nuevo más tarde. Si el problema persiste, no dudes en ponerte en contacto con nuestro equipo de soporte.",
  },
  GET_CARDS: {
    title: "Error al obtener tarjetas",
    message:
      "Lamentablemente, no pudimos obtener tus tarjetas en este momento. Por favor, inténtalo de nuevo más tarde. Si el problema persiste, no dudes en ponerte en contacto con nuestro equipo de soporte.",
  },
  POST_CARD: {
    title: "No se pudo agregar tu tarjeta",
    message:
      "Lamentablemente, no pudimos agregar tu tarjeta en este momento. Te recomendamos revisar los detalles de tu tarjeta y volver a intentarlo. Si el problema persiste, no dudes en ponerte en contacto con nuestro equipo de soporte.",
  },
  POST_PAYMENT: {
    title: "No se pudo procesar el pago",
    message:
      "Lamentablemente, no pudimos procesar tu pago en este momento. Por favor, inténtalo de nuevo más tarde. Si el problema persiste, no dudes en ponerte en contacto con nuestro equipo de soporte.",
  },
  SET_DEFAULT_CARD: {
    title: "No se pudo establecer la tarjeta predeterminada",
    message:
      "Lamentablemente, no pudimos establecer esta tarjeta como tu tarjeta predeterminada en este momento. Por favor, inténtalo de nuevo más tarde. Si el problema persiste, no dudes en ponerte en contacto con nuestro equipo de soporte.",
  },
  DELETE_CARD: {
    title: "No se pudo eliminar tu tarjeta",
    message:
      "Lamentablemente, no pudimos eliminar tu tarjeta en este momento. Por favor, inténtalo de nuevo más tarde. Si el problema persiste, no dudes en ponerte en contacto con nuestro equipo de soporte.",
  },
  ALTER_SUBSCRIPTION: {
    title: "No se pudo actualizar tu suscripción",
    message:
      "Lamentablemente, no pudimos actualizar tu suscripción en este momento. Por favor, inténtalo de nuevo más tarde. Si el problema persiste, no dudes en ponerte en contacto con nuestro equipo de soporte.",
  },
  CHECKOUTPRO_ERROR: {
    title: "Error al generar el enlace de pago",
    message:
      "Lamentablemente, no pudimos generar el enlace de pago en este momento. Por favor, inténtalo de nuevo más tarde. Si el problema persiste, no dudes en ponerte en contacto con nuestro equipo de soporte.",
  },
  TOKEN_HAS_EXPIRED: {
    value: "Token has expired",
    title: "Tu sesión ha expirado",
    message: "Por favor, vuelva a iniciar el proceso.",
  },
  TYPE_UNDEFINED: {
    title: "Error en la solicitud",
    message: "Por favor, vuelva a iniciar el proceso.",
  },
});
