import styled from "styled-components";

const MPInput = styled.div`
  border: 1px solid #cecece;
  border-radius: 5px;
  background-color: white;
  overflow: hidden;
`;

export const MPInputStyles = {
  inputStyle: {
    fontFamily: "Rubik, sans-serif",
    fontSize: "16px",
    fontWeight: "300",
    placeholderColor: "#828282",
  },
  customFonts: [
    {
      src: "https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,600;0,700;0,800;1,400&display=swap",
    },
  ],
  onFocus: (id) => () => {
    document.getElementById(id).style.border = "1px solid #0072CE";
    document.getElementById(id).style.boxShadow =
      "0 0 0 0.2rem rgba(0, 114, 206, 0.25)";
  },
  onBlur: (id) => () => {
    document.getElementById(id).style.border = "1px solid #cecece";
    document.getElementById(id).style.boxShadow = "none";
  },
};

export default MPInput;
