import styled from "styled-components";

export const HeaderContainer = styled.header``;

export const H1 = styled.h1`
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral.main};
  padding: 0;
  margin: 0 30px 14px 30px;
`;

export const Hr = styled.hr`
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
  padding: 0;
  margin: 0;
  margin-bottom: 35px;
  @media (max-width: 445px) {
    margin-bottom: 15px;
  }
`;
